<template>
<div>
    <div v-if="isLoading" class="loading-container">
        <vue-loaders-ball-clip-rotate color="#70D3FF" scale="2.1"></vue-loaders-ball-clip-rotate>
    </div>
    <div v-else>
        <div v-if="!connectAccount">
            <user-Information />
        </div>
        <div v-else>
            <add-Property />
        </div>
    </div>
    
</div>
</template>

<script>
import API from '../api';
import addProperty from './add_property.vue';
import userInformation from '../guest/user_information.vue';

export default {
    data() {
        return {
            totalRecords: 0,
            swtiched: 0,
            connectAccount: null,
            isLoading: true,
        }
    },
    components: {
        addProperty,
        userInformation,
    },
    mounted() {
        API.getConnectAccount(data => {
            this.connectAccount = data.data;
            setTimeout(() => {
                this.isLoading = false;
            }, 200)
        }, error => {
            console.log(error);
        })
        API.getProperties(this.serverParams,
        data => {
            this.totalRecords = data.totalRecords;
        }, err => {
            console.log(err)
        })
    }
}

</script>
<style>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100vw;
}
</style>